import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import "firebase/auth";
// Your web app's Firebase configuration
// var firebaseConfig = {
//     apiKey: "AIzaSyCejLPc1veUSvASaM1yiKf3StXJMdkQmxA",
//     authDomain: "the-third-santa-1399a.firebaseapp.com",
//     projectId: "the-third-santa-1399a",
//     storageBucket: "the-third-santa-1399a.appspot.com",
//     messagingSenderId: "757041332896",
//     appId: "1:757041332896:web:c4ed8251f32901c4b3ffc5"
// };

var firebaseConfig = {
    apiKey: "AIzaSyBf6yT1VVFkg49-V4aKl9uYU7nQtJPHA9w",
    authDomain: "the-third-santa.firebaseapp.com",
    databaseURL: "https://the-third-santa.firebaseio.com",
    projectId: "the-third-santa",
    storageBucket: "the-third-santa.appspot.com",
    messagingSenderId: "893545161575",
    appId: "1:893545161575:web:26b359b6aa1ebf45ae45c2",
    measurementId: "G-JJQ8EDS0R6"
};




   



// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const ttsFireStorage = firebase.storage();
const ttsFireStore = firebase.firestore();

const ttsAuth=firebase.auth();


const timestamp = firebase.firestore.FieldValue.serverTimestamp;
export { ttsFireStorage, ttsFireStore, timestamp,ttsAuth };
