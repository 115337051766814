import React, { useEffect } from "react";
import "./nav.css";
// import cx from "classnames";
// import Logo from "../../asset/images/logo-tts.jpg";
import { NavLink, useHistory } from "react-router-dom";
import { useAuthFunction } from "../../context/authContext";
import gsap from "gsap/gsap-core";
// import { gsap,Power3 } from 'gsap';
//import "./pop.css";
const Nav = (props) => {
  const {
    enter,
    exit,
    setDirection,
    direction,
    showMenuAnimated,
    setLinkHovered,
  } = props;

  const { currentUser, signOut } = useAuthFunction();
  const his = useHistory();
  useEffect(() => {
    setLinkHovered();
    // gsap.from('.logo',{duration:.6, y:-50,ease:'back.inOut(6)'});
    //onHoverEff();
  }, []);
  const Redirect = (e, link) => {
    e.preventDefault();

    if (his.location.pathname.toString() === link) return true;

    if (direction === "down") setDirection("up");
    else setDirection("down");

    enter();

    setTimeout(() => {
      his.push(link);
      exit();
      showMenuAnimated();
      // setStatusY1(y1);
    }, 1500);
  };

  return (
    <header>
      <NavLink className="logo" to="/" onClick={(e) => Redirect(e, "/")}>
        {/* <img src={Logo} /> */}

        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 561.2986 814.75879"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1-2">
              <path
                className="cls-1"
                d="M30.65614,108.5316Q50.889,90.756,85.5294,90.7518q22.99148,0,39.39256,7.97034,16.39563,7.97453,24.831,21.765a58.20983,58.20983,0,0,1,8.43034,30.96225q0,20.23278-10.11639,32.95523-10.1164,12.72369-23.60494,17.32066v1.22607q17.47415,5.82641,27.59011,19.31282,10.1164,13.49319,10.11639,34.64046,0,19.01175-8.73644,33.56827-8.7377,14.56407-25.444,22.83884-16.71225,8.276-39.6995,8.27642-36.78609,0-58.70538-18.08673-21.92185-18.08468-23.145-54.56717H57.32626q.30147,13.4931,7.66424,21.30582,7.35647,7.81686,21.45888,7.81729,11.95551,0,18.54673-6.89731,6.58827-6.8973,6.59037-18.24063,0-14.71417-9.34948-21.305-9.35451-6.58952-29.88919-6.59122h-9.8103V182.41157h9.8103q15.63374,0,25.291-5.3643,9.656-5.35965,9.65641-19.15977,0-11.036-6.13124-17.16761-6.1354-6.12785-16.86065-6.13122-11.654,0-17.32065,7.05121a31.5917,31.5917,0,0,0-6.59038,17.47369H9.19726Q10.41829,126.31475,30.65614,108.5316Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-1"
                d="M292.76,319.74905l-44.75686-81.23727H235.43457v81.23727h-52.4211V104.54643H270.995q25.44108,0,43.37774,8.89032,17.932,8.89536,26.82233,24.371a68.03037,68.03037,0,0,1,8.89032,34.48741,64.0628,64.0628,0,0,1-12.10773,38.31954q-12.11443,16.8636-35.71432,23.91186l49.662,85.22246ZM235.43457,201.4183H267.929q14.40642,0,21.61276-7.05037,7.19878-7.04866,7.20425-19.92668,0-12.257-7.20425-19.31282-7.20634-7.04867-21.61276-7.05121h-32.4944Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-1"
                d="M507.96007,118.035a96.43247,96.43247,0,0,1,39.39254,37.85954q13.946,24.37145,13.946,56.25322,0,31.57779-13.946,56.10017a95.8628,95.8628,0,0,1-39.5473,38.01261Q482.209,319.75368,448.486,319.749H367.86436V104.54643H448.4861Q482.51854,104.54642,507.96007,118.035ZM491.40382,258.13153q16.55453-16.24553,16.55625-45.9838,0-29.73112-16.55625-46.28988-16.54949-16.55457-46.28818-16.55373h-24.831V274.37829h24.831Q474.84419,274.37829,491.40382,258.13153Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-2"
                d="M44.45077,560.88177a64.25187,64.25187,0,0,1-27.87434-21.15612q-10.43931-14.004-11.0061-33.73481H57.60208q1.13905,11.15076,7.71891,17.01038,6.57314,5.86294,17.15331,5.86127,10.86187,0,17.1533-5.00354,6.28552-4.99769,6.28933-13.8653a16.42636,16.42636,0,0,0-5.0027-12.29272,40.4356,40.4356,0,0,0-12.29357-8.00565,198.84088,198.84088,0,0,0-20.72642-7.14789A225.38152,225.38152,0,0,1,36.16,470.54059a58.57,58.57,0,0,1-21.1553-17.72522Q6.13964,441.0958,6.14216,422.22488q0-28.01441,20.29755-43.884,20.29587-15.86709,52.88951-15.86667,33.16084,0,53.46134,15.86667,20.29586,15.86709,21.728,44.16989H101.62906q-.57648-9.71526-7.14708-15.29486-6.58071-5.57534-16.86739-5.57538-8.86509,0-14.295,4.71762Q57.8855,411.07582,57.888,419.9375q0,9.72285,9.14849,15.15274,9.14638,5.43411,28.58912,11.72089A265.22783,265.22783,0,0,1,127.216,459.39062a60.19638,60.19638,0,0,1,21.01316,17.43927q8.85752,11.43837,8.86256,29.4469a57.788,57.788,0,0,1-8.7196,31.16156q-8.7251,14.0116-25.30109,22.29981-16.58483,8.295-39.16719,8.29156Q61.88622,568.02972,44.45077,560.88177Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-2"
                d="M298.03264,530.578h-74.9034l-12.00681,35.45026H159.94757l72.61607-200.69574h56.60642l72.61606,200.69574H310.04027Zm-12.5795-37.73761-24.87223-73.47467-24.58628,73.47467Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-2"
                d="M549.32716,566.02826h-48.885L418.67677,442.23739V566.02826H369.79011V365.33255h48.88666l81.76538,124.36273V365.33255h48.885Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-2"
                d="M107.90825,767.85669v44.45H81.23813q-28.51008,0-44.45078-13.94769-15.944-13.94606-15.94152-45.52466V684.77948H0V641.24786H20.84584V599.55615H73.2669V641.2478h34.3344v43.53162H73.2669v68.6687q0,7.66671,3.67907,11.03638,3.67823,3.37554,12.26245,3.37213Z"
                transform="translate(0 0)"
              />
              <path
                className="cls-2"
                d="M301.95472,774.29321h-80.3173L208.7619,812.30664H153.88867L231.75383,597.104h60.69839l77.86432,215.20264h-55.4863ZM288.467,733.82764l-26.671-78.78516-26.364,78.78516Z"
                transform="translate(0 0)"
              />
              <g>
                <path
                  className="cls-2"
                  d="M57.88167,0V15.58252H39.10237V70.42187H19.52424V15.58252H.94514V0Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-2"
                  d="M128.20295,0V70.42187H108.62483V42.15332h-23.874V70.42187H65.17268V0H84.75081V26.4707h23.874V0Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-2"
                  d="M158.9676,15.68261V27.16992h22.47559v14.8833H158.9676v12.686h25.47168V70.42187h-45.0498V0h45.0498V15.68261Z"
                  transform="translate(0 0)"
                />
              </g>
              <circle
                className="cls-2"
                cx="456.27533"
                cy="778.76807"
                r="35.99071"
              />
            </g>
          </g>
        </svg>
      </NavLink>
      <input type="checkbox" id="check" />
      <ul>
        <li className="link q_magnet">
          <NavLink
            to="/"
            activeClassName="Selected"
            exact={true}
            onClick={(e) => Redirect(e, "/")}
          >
            Home
          </NavLink>
        </li>
        <li className="link q_magnet">
          <NavLink
            to="/work"
            activeClassName="Selected"
            exact={true}
            onClick={(e) => Redirect(e, "/work")}
          >
            Work
          </NavLink>
        </li>

        <li className="link q_magnet">
          <a href="http://calendly.com/tts-amitesh/" rel="noopener noreferrer" target="_blank">Contact</a>
          
          
        </li>

      </ul>
      <label htmlFor="check" className="bar"></label>
    </header>
  );
};
export default Nav;
