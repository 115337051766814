import React from 'react'; 
import { Route,Redirect, } from 'react-router';

import { useAuthFunction } from '../context/authContext';

const PrivateRoute=({children,...restProps})=>{
    const {currentUser}=useAuthFunction();

    return(<Route {...restProps} render={()=>currentUser?children:<Redirect to='/login' /> } ></Route>);


}

export default PrivateRoute;
