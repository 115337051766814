import React from "react";
import "./spinner.css";
const Spinner1 = (props) => {
  return (
    //         <div className="spinner">
    //   <div className="cube1"></div>
    //   <div className="cube2"></div>
    // </div>

    <div class="sk-chase">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
  );
};

const Spinner = (props) => {
  return (
    <div className="spinner">
      <div className="cube1"></div>
      <div className="cube2"></div>
    </div>
  );
};

export default Spinner;
export { Spinner1 };
