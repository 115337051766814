import React, { Suspense, useEffect, useState } from "react";

import { Switch, Route } from "react-router-dom";
import "./App.css";
import Nav from "./components/nav/nav";
//import ShowCase from './components/showcase';
// import { CSSTransition, TransitionGroup } from "react-transition-group";

// import { Link, NavLink } from "react-router-dom";

import Spinner from "./components/ui-components/spinner/spinner";
import { AuthProvider } from "../src/context/authContext";
import PrivateRoute from "./route/privateroute";
import AnimatedCursor from "react-animated-cursor";
import { gsap, Power4, Cubic } from "gsap";
import { usePageTracking } from "./hooks/usePageTracking";
// import Cursor from "./components/ui-components/cursor/cursor";
// import Home from "./components/home";
// import Showcase from "./components/showcase";
// import { delay } from "q";
// import useLocoScroll from "./hooks/useLocomotive";

const Showcase = React.lazy(() => import("./components/showcase"));
const Home = React.lazy(() => import("./components/home"));
const UploadForm = React.lazy(() => import("./admin/picupload/picupload"));
// const About = React.lazy(() => import("./components/about/about"));
const Signin = React.lazy(() => import("./admin/user/signin"));

function App() {
  const [preload, setPreLoad] = useState(true);
  const [direction, setDirection] = useState("down");
   usePageTracking();
  const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
  };

  const showLoading = () => {
    gsap.to(".loader-text", 0.2, {
      y: 0,
      delay: 0.5,
      ease: Power4.easeInOut,
    });
    gsap.to(".full-load", 2, {
      y: 0,
      opacity: 1,
      delay: 0,
      ease: Power4.easeInOut,
    });

    setPreLoad(true);
  };

  const hideLoading = () => {
    let y1, y2;
    if (direction === "down") {
      y1 = "80%";
      y2 = "150%";
    } else {
      y1 = "-80%";
      y2 = "-150%";
    }

    gsap.to(".loader-text", 2.5, {
      delay: 0,
      y: y1,
      ease: Power4.easeInOut,
    });
    gsap.to(".full-load", 2, {
      delay: 0.5,
      y: y2,
      // opacity: 0,
      ease: Power4.easeInOut,
    });

    setPreLoad(false);
  };

  const showMenuAnimated = () => {
    gsap.from(".logo", { y: 20, opacity: 0, delay: 1, duration: 1 });
    gsap.from(".link", {
      y: 20,
      opacity: 0,
      delay: 1.2,
      duration: 1,
      stagger: 0.2,
    });
  };

  const initialLoader = () => {
    gsap.to(".full-load", 2, {
      y: "-150%",
      delay: 0.2,
      // opacity: 0.2,
      ease: Power4.easeInOut,
    });
    gsap.to(".loader-text", 2.5, {
      y: "-80%",
      // opacity: 0.2,
      ease: Power4.easeInOut,
    });
  };
  // useLocoScroll(!preload);
  const [linkHovered, setLinkHovered] = React.useState(false);

  const handleLinkHoverEvents = () => {
    if (!isMobile()) {
      document.querySelectorAll("a").forEach((el) => {
        el.addEventListener("mouseover", () => setLinkHovered(true));
        el.addEventListener("mouseout", () => setLinkHovered(false));
      });

      const btns = document.querySelectorAll(".q_magnet");
      btns.forEach((btn) => {
        btn.addEventListener("mousemove", function (e) {
          //  document.removeEventListener("mousemove",move);
          const position = btn.getBoundingClientRect();
          const x = e.pageX - position.left - position.width / 2;
          const y = e.pageY - position.top - position.height / 2;
          btn.style.transform =
            "translate(" + x * 0.3 + "px," + y * 0.3 + "px) scale(1.1)";
          // btn.style.transform = "scale(1.2)";
          //       cursor.style.left=position.left + position.width / 2 + "px";
          // cursor.style.top= position.top + position.height / 2 + "px";
          //console.log(x);
        });
        btn.addEventListener("mouseout", function (e) {
          btn.style.transform = "translate(0px,0px)";
          // document.addEventListener("mousemove", move);
        });
      });
    }
  };

  const textAnimationTTS = () => {
    gsap.to(".tts", 0.9, {
      y: 0,
      opacity: 1,
      stagger: 0.1,
      ease: Power4.easeOut,
    });
  };

  useEffect(() => {
    textAnimationTTS();
    let timer1 = setTimeout(() => {
      initialLoader();
      showMenuAnimated();
    }, 700);
    handleLinkHoverEvents();
    return () => {
      clearTimeout(timer1);
    };

    // setPreLoad(false);
  }, []);

  // useEffect(() => {
  //   const scroll = new locomotiveScroll({
  //     el: scrollRef.current,
  //     smooth: true,
  //     smartphone: {
  //       smooth: true,
  //     },
  //     tablet: {
  //       smooth: true,
  //     },
  //   });
  // });

  const routes = [
    { path: "/work", name: "Work", Component: Showcase },
    { path: "/", name: "Home", Component: Home },
  ];

  return (
    <div className="App">
      <section id="scroll-container">
        <div className="full-load">
          <div className="loader-text">
            <h1>
              <span className="tts">The</span>
              <span className="tts">Third</span>
              <span className="tts">Santa</span>
            </h1>
          </div>
        </div>

        <AuthProvider>
          <AnimatedCursor
           innerSize={16}
           outerSize={40}
           color='173,201,22'
           outerAlpha={0.5}
           innerScale={0.5}
           outerScale={1.5}
           trailingSpeed={12}
          />
          {/* <Cursor linkHovered={linkHovered} /> */}
          <Nav
            enter={showLoading}
            exit={hideLoading}
            setDirection={setDirection}
            direction={direction}
            showMenuAnimated={showMenuAnimated}
            setLinkHovered={handleLinkHoverEvents}
          />

          {/* <Home pop="sfsdfsf" /> */}

          <Switch>
            <Suspense fallback={<Spinner />}>
              <Route
                exact
                path="/"
                render={() => (
                  <Home
                    enter={showLoading}
                    exit={hideLoading}
                    direction={direction}
                    setDirection={setDirection}
                    setLinkHovered={handleLinkHoverEvents}
                  />
                )}
              />

              <Route exact path="/work">
                <Showcase
                  setLinkHovered={handleLinkHoverEvents}
                />{" "}
              </Route>
              <PrivateRoute exact path="/upload">
                <UploadForm />
              </PrivateRoute>
              <Route exact path="/login" component={Signin} />
            </Suspense>
          </Switch>
        </AuthProvider>
      </section>
    </div>
  );
}

export default App;
