import React, {useContext, useEffect, useState} from 'react';

import {ttsAuth} from '../firebase/config';

const AuthContext= React.createContext();

export function useAuthFunction()
{
    return useContext(AuthContext);
}

export function AuthProvider({children})
{
    const [currentUser,setCurrentUser]=useState(null);
    const [loading,setLoading]=useState(true);
   


    const signIn=(email,password)=>
    {
        return ttsAuth.signInWithEmailAndPassword(email,password);
        // .then(result=>{
        //     setCurrentUser(result.user);
        //     console.log(result.user);
        //     return true;
        // }).catch(error=>{
        //     console.log(error);
        //     return true;
        // });
    }

    const signOut=()=>
    {
        return ttsAuth.signOut();
    }

    const userDetails={
        currentUser,
        signIn,
        signOut
    }

    
    
    useEffect(()=>{
        const unsubscribe=ttsAuth.onAuthStateChanged((user)=>{
            setLoading(false);
          if(user)
          {
              setCurrentUser(user);   
             
              localStorage.setItem('authUser', user.uid)
           //  const p= JSON.parse(localStorage.getItem('authUser'));
            
          }
           
            else
            {  setCurrentUser(null);
                localStorage.removeItem('authUser');
            }
          
              
        });
        return ()=> unsubscribe();
    },[currentUser]);
    
    
    
    
        return (
            <AuthContext.Provider value={userDetails}>
                {!loading && children}
            </AuthContext.Provider>
        )
    

}
